import { disabledDay, TextArea } from "components/shared";
import { DatePickerInput } from "components/shared/DatePickerInput";
import {
  Checkbox,
  SearchDropdown,
  SearchSelectOption,
} from "components/shared/Inputs";
import { TableStrandStandard } from "components/strands/TableStrandStandard";
import dayjs from "dayjs";
import { getTitle } from "../utils";

type TabContentProps = {
  activeTab: number;
  cohortStartDate: string;
  cohortEndDate: string;
  startDate: Date;
  endDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  applyToFullRange: boolean;
  setApplyToFullRange: (value: boolean) => void;
  options: { value: string; label: string }[];
  items: { id: string; key: string; description: string }[];
  onSelect: (option: SearchSelectOption | null) => void;
  handleRemoveItem: (index: number) => void;
  notes: string;
  setNotes: (value: string) => void;
  loading: boolean;
};

export const TabContent = ({
  activeTab,
  cohortStartDate,
  cohortEndDate,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  applyToFullRange,
  setApplyToFullRange,
  options,
  items,
  onSelect,
  handleRemoveItem,
  notes,
  setNotes,
  loading,
}: TabContentProps) => {
  const title = getTitle(activeTab);

  return (
    <div className="flex flex-col gap-y-4 mt-4">
      <div className="grid grid-cols-12 gap-x-6 gap-y-4">
        <div className="col-span-6">
          <DatePickerInput
            label="From Date"
            required
            onChange={(date) => date && setStartDate(date.toDate())}
            disabledDate={(date) =>
              disabledDay(
                date,
                new Date(cohortStartDate),
                new Date(cohortEndDate),
                startDate,
                endDate,
                true
              )
            }
            value={dayjs(startDate)}
          />
        </div>
        <div className="col-span-6">
          <DatePickerInput
            label="To Date"
            required
            onChange={(date) => date && setEndDate(date.toDate())}
            disabledDate={(date) =>
              disabledDay(
                date,
                new Date(cohortStartDate),
                new Date(cohortEndDate),
                startDate,
                endDate,
                false
              )
            }
            value={dayjs(endDate)}
          />
        </div>

        <div className="col-span-12 -mt-2">
          <Checkbox
            label="Apply to full cohort date range"
            className="text-sm"
            checked={applyToFullRange}
            onChange={() => setApplyToFullRange(!applyToFullRange)}
          />
        </div>

        <div className="col-span-12">
          <h3 className="text-sm font-medium text-gray-700 mb-2">{title}</h3>
          <div className="space-y-2">
            <SearchDropdown
              name={title}
              placeholder={`Add ${title}`}
              options={options}
              onSelect={onSelect}
              isLoading={loading}
              clearAfterSelect={true}
            />
            <TableStrandStandard
              data={items}
              handleRemoveItem={handleRemoveItem}
              dataName={title}
            />
          </div>
        </div>

        <div className="col-span-12">
          <label className="block text-sm font-medium text-gray-700">
            Notes
          </label>
          <TextArea
            id="notes"
            rows={4}
            className="w-full mt-1"
            placeholder={`Write some notes here for the selected ${title.toLowerCase()}...`}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};
