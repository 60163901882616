import { gql, useQuery } from "@apollo/client";
import {
  CohortStrandStandardCard_CohortStrandStandardFragment,
  CohortStrandStandardCardQuery,
  CohortStrandStandardCardQueryVariables,
} from "@generated/graphql";
import { fetchErrToast } from "@utils/errorLogging";
import { Card, Icon, Table } from "components/shared";
import { HeaderColors } from "components/shared/Card";
import { getAddModalOpenHeader } from "components/shared/Card/helpers";
import { useAuth } from "contexts/AuthProvider";
import { getNormalizedShortReadableDate } from "helpers/dateText";
import { useState } from "react";
import { CellProps, Column } from "react-table";
import {
  AddCohortStrandStandard,
  STANDARDS_SEARCH,
  STRANDS_SEARCH,
} from "./components/AddCohortStrandStandard";
import { DeleteCohortStrandStandards } from "./components/DeleteCohortStrandStandards";
import { StandardsStrandsList } from "./components/StandardsStrandsList";

const REFETCH_QUERIES = [STANDARDS_SEARCH, STRANDS_SEARCH];

CohortStrandStandardCard.fragments = {
  cohortStrandStandard: gql`
    fragment CohortStrandStandardCard_CohortStrandStandard on CohortStrandStandard {
      id
      fromDate
      toDate
      strands {
        id
        key
        description
        url
        notes
      }
      standards {
        id
        key
        description
        url
        notes
      }
      ...DeleteCohortStrandStandards_CohortStrandStandard
      ...StandardsStrandsList_CohortStrandStandard
    }
    ${DeleteCohortStrandStandards.fragments.cohortStrandStandard}
    ${StandardsStrandsList.fragments.cohortStandardStrand}
  `,
};

const GET_COHORT_STRAND_STANDARDS = gql`
  query CohortStrandStandardCard($cohortId: ID!) {
    getCohortStrandStandards(cohortId: $cohortId) {
      ...CohortStrandStandardCard_CohortStrandStandard
    }
  }
  ${CohortStrandStandardCard.fragments.cohortStrandStandard}
`;

type Props = {
  cohortId: string;
  cohortStartDate: string;
  cohortEndDate: string;
};

export function CohortStrandStandardCard({
  cohortId,
  cohortStartDate,
  cohortEndDate,
}: Props) {
  const { isAdmin } = useAuth();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [period, setPeriod] =
    useState<CohortStrandStandardCard_CohortStrandStandardFragment | null>(
      null
    );

  const { data, loading } = useQuery<
    CohortStrandStandardCardQuery,
    CohortStrandStandardCardQueryVariables
  >(GET_COHORT_STRAND_STANDARDS, {
    variables: { cohortId },
    onError: (err) => fetchErrToast("Instructional Plan", err),
  });

  const openDeleteModal = (
    item: CohortStrandStandardCard_CohortStrandStandardFragment
  ) => {
    setPeriod(item);
    setShowDeleteModal(true);
  };

  const closeModal = () => {
    setShowDeleteModal(false);
    setPeriod(null);
  };

  const openAddModal = () => isAdmin && setShowAddModal(true);

  return (
    <>
      {!loading && (data?.getCohortStrandStandards.length !== 0 || isAdmin) ? (
        <>
          <Card
            icon="strand"
            headerOverlayColor={HeaderColors.Emerald}
            header={getAddModalOpenHeader("Instructional Plan", openAddModal)}
          >
            <Table
              pageSize={100}
              loading={loading}
              showPagination={false}
              cellPadding="px-2 py-1"
              verticalDividers={false}
              dataName="Instructional Plan"
              emptyStateIcon={<Icon icon="strand" />}
              data={data?.getCohortStrandStandards ?? []}
              className="min-h-0 rounded-t-none border-none"
              showHeaders={!!data?.getCohortStrandStandards.length}
              columns={getCohortStrandStandardsColumns(openDeleteModal)}
            />
          </Card>

          <AddCohortStrandStandard
            cohortId={cohortId}
            show={showAddModal}
            cohortEndDate={cohortEndDate}
            cohortStartDate={cohortStartDate}
            refetchQueries={[GET_COHORT_STRAND_STANDARDS, ...REFETCH_QUERIES]}
            closeModal={() => setShowAddModal(false)}
          />

          {period && (
            <DeleteCohortStrandStandards
              input={period}
              show={showDeleteModal}
              refetchQueries={[GET_COHORT_STRAND_STANDARDS, ...REFETCH_QUERIES]}
              closeModal={() => closeModal()}
            />
          )}
        </>
      ) : null}
    </>
  );
}

const getCohortStrandStandardsColumns = (
  openDeleteModal: (
    item: CohortStrandStandardCard_CohortStrandStandardFragment
  ) => void
): Column<CohortStrandStandardCard_CohortStrandStandardFragment>[] => [
  {
    Header: () => (
      <div className="absolute top-0 left-0 w-full h-full flex flex-center">
        Period
      </div>
    ),
    accessor: "fromDate",
    width: 120,
    Cell: ({
      row: {
        original: { fromDate, toDate },
      },
    }: CellProps<CohortStrandStandardCard_CohortStrandStandardFragment>) => (
      <div className="flex flex-col text-center items-center justify-center">
        <span>{getNormalizedShortReadableDate(new Date(fromDate))}</span>
        <span>-</span>
        <span>{getNormalizedShortReadableDate(new Date(toDate))}</span>
      </div>
    ),
  },
  {
    Header: () => (
      <div className="w-full flex flex-center text-center pl-[10px]">
        Standards/Strands
      </div>
    ),
    accessor: "standards",
    Cell: ({
      row: { original },
    }: CellProps<CohortStrandStandardCard_CohortStrandStandardFragment>) => {
      return (
        <div className="w-full flex justify-center">
          <StandardsStrandsList
            item={original}
            openEditModal={openDeleteModal}
          />
        </div>
      );
    },
  },
];
