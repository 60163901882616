import { gql } from "@apollo/client";
import { StandardStrandListItems_CohortStrandStandardDetailFragment } from "@generated/graphql";
import clsx from "clsx";
import { Checkbox, Link, StandardStrandBadge } from "components/shared";
import { LinkType } from "../types";

StandardStrandListItems.fragments = {
  detail: gql`
    fragment StandardStrandListItems_CohortStrandStandardDetail on CohortStrandStandardDetail {
      id
      key
      description
      url
      notes
    }
  `,
};

type Props = {
  items: StandardStrandListItems_CohortStrandStandardDetailFragment[];
  type: LinkType;
  selectedItemIds?: string[];
  onToggleItem?: (id: string) => void;
  className?: string;
};

export function StandardStrandListItems({
  items,
  type,
  selectedItemIds,
  onToggleItem,
  className,
}: Props) {
  return (
    <ul className="list-none p-0 m-0 w-full">
      {items.map(({ key, description, url, notes, id }) => {
        const isSelected = (selectedItemIds ?? []).includes(id);

        return (
          <li key={key} className="my-2 w-full relative">
            <div className="grid grid-cols-[auto_1fr_auto] gap-4 items-center justify-between">
              <div className="flex items-center">
                <StandardStrandBadge
                  type={type}
                  badgeKey={key}
                  className={clsx(
                    "absolute top-0 left-2 text-center h-fit !w-[80px] inline-flex items-center",
                    className
                  )}
                />
              </div>
              <div className="flex flex-col gap-1">
                <span
                  className="break-words whitespace-normal text-sm"
                  style={{ textIndent: "80px" }}
                >
                  <Link
                    href={url ?? ""}
                    disabled={!url}
                    className={clsx(
                      url
                        ? "text-blue-500 break-words whitespace-normal"
                        : "text-gray-500 break-words whitespace-normal"
                    )}
                    target="_blank"
                  >
                    {description}
                  </Link>
                </span>
                {notes && (
                  <span className="text-xs italic text-gray-500 break-words whitespace-normal">
                    Notes: {notes}
                  </span>
                )}
              </div>
              <div className="flex items-start justify-end">
                {onToggleItem && (
                  <Checkbox
                    checked={isSelected}
                    onChange={() => onToggleItem(id)}
                    labelSide="right"
                  />
                )}
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
}
