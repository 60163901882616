import { SessionReportInstructionType } from "@generated/graphql";
import { SessionReportStudentEvaluationRow } from "@shared/SessionReports/types";
import { Icon, Modal } from "components/shared";
import { useState } from "react";
import { SessionModalPageHeader } from "../../../helpers";
import {
  InstructionData,
  SessionConcernData,
  StudentPerformanceMap,
} from "../../../types";
import { ConcernsSection } from "./components/ConcernsSection/ConcernsSection";
import { InstructionSection } from "./components/InstructionSection/InstructionSection";
import { StudentPerformanceSection } from "./components/StudentPerformanceSection/StudentPerformanceSection";

type Props = {
  canSave: boolean;
  readOnly: boolean;
  isMasteryRequired: boolean;
  allEvaluationsComplete: boolean;
  instructionData: InstructionData;
  concernsData: SessionConcernData;
  studentPerformanceMap: StudentPerformanceMap;
  instructionType: SessionReportInstructionType;
  activeStudents: SessionReportStudentEvaluationRow[];
  attendedStudents: SessionReportStudentEvaluationRow[];
  onSave: () => void;
  onClose: () => void;
  onGoBack: () => void;
  setInstructionData: (data: InstructionData) => void;
  setConcernsData: (concerns: SessionConcernData) => void;
  setStudentPerformanceMap: (data: StudentPerformanceMap) => void;
};

export const TutorNotesModalBody = ({
  canSave,
  readOnly,
  concernsData,
  activeStudents,
  instructionData,
  instructionType,
  attendedStudents,
  isMasteryRequired,
  studentPerformanceMap,
  allEvaluationsComplete,
  onSave,
  onClose,
  onGoBack,
  setConcernsData,
  setInstructionData,
  setStudentPerformanceMap,
}: Props) => {
  const [showIncomplete, setShowIncomplete] = useState(false);

  return (
    <div className="flex flex-col gap-y-5">
      {!readOnly && (
        <SessionModalPageHeader
          title="Tutor Notes"
          description=" Please complete all required fields to save this session report."
        />
      )}

      <InstructionSection
        readOnly={readOnly}
        instructionType={instructionType}
        instructionData={instructionData}
        highlightIncomplete={showIncomplete}
        allStudentsAbsent={attendedStudents.length === 0}
        setInstructionData={setInstructionData}
      />

      <StudentPerformanceSection
        readOnly={readOnly}
        attendedStudents={attendedStudents}
        highlightIncomplete={showIncomplete}
        isMasteryRequired={isMasteryRequired}
        studentPerformanceMap={studentPerformanceMap}
        setStudentPerformanceMap={setStudentPerformanceMap}
      />

      <ConcernsSection
        readOnly={readOnly}
        students={activeStudents}
        concernsData={concernsData}
        highlightIncomplete={showIncomplete}
        setConcernsData={setConcernsData}
      />

      {!readOnly && (
        <Modal.Buttons className="flex w-full items-end justify-between z-0 !mt-1">
          <div className="flex items-center gap-3">
            <Modal.Button type="cancel" onClick={onClose}>
              Cancel
            </Modal.Button>
            <div
              className="flex items-center w-fit"
              onMouseOver={() => setShowIncomplete(true)}
              onMouseLeave={() => setShowIncomplete(false)}
            >
              <Modal.Button type="confirm" disabled={!canSave} onClick={onSave}>
                Save
              </Modal.Button>
            </div>
          </div>
          <div className="flex flex-col gap-y-1 items-center cursor-pointer">
            <span className="text-2xs font-bold text-gray-800">
              Prev: Evaluation
            </span>
            <Modal.Button
              type={allEvaluationsComplete ? "confirm" : "delete"}
              onClick={onGoBack}
            >
              <div className="flex items-center gap-x-2 px-2">
                <Icon
                  icon="chevronLeft"
                  color="text-white"
                  size={6}
                  className="-mr-[5px] -ml-[16px]"
                />
                Back
              </div>
            </Modal.Button>
          </div>
        </Modal.Buttons>
      )}
    </div>
  );
};
