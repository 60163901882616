import { DocumentNode, gql, useMutation, useQuery } from "@apollo/client";
import {
  AddCohortStrandStandard_SearchedStrandStandardFragment,
  CreateCohortStrandStandardInput,
} from "@generated/graphql";
import { fetchErrToast } from "@utils/errorLogging";
import {
  ErrorBox,
  getOrCreateDate,
  Modal,
  OptionsToggleButton,
} from "components/shared";
import { SearchSelectOption } from "components/shared/Inputs";
import { uniqBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { LinkType } from "../types";
import { getTitle } from "../utils";
import { TabContent } from "./TabContent";

AddCohortStrandStandard.fragments = {
  searchedStrandStandard: gql`
    fragment AddCohortStrandStandard_SearchedStrandStandard on SearchedStrandStandard {
      id
      key
      description
      url
    }
  `,
};

export const STRANDS_SEARCH = gql`
  query SearchStrands($cohortId: ID!) {
    getSearchStrands(cohortId: $cohortId) {
      id
      key
      description
      url
      ...AddCohortStrandStandard_SearchedStrandStandard
    }
  }
  ${AddCohortStrandStandard.fragments.searchedStrandStandard}
`;

export const STANDARDS_SEARCH = gql`
  query SearchStandards($cohortId: ID!) {
    getSearchStandards(cohortId: $cohortId) {
      id
      key
      description
      url
      ...AddCohortStrandStandard_SearchedStrandStandard
    }
  }
  ${AddCohortStrandStandard.fragments.searchedStrandStandard}
`;

const CREATE_COHORT_STANDARDS = gql`
  mutation CreateCohortStandards($input: CreateCohortStrandStandardInput!) {
    createCohortStrandStandards(input: $input) {
      count
    }
  }
`;

type Props = {
  cohortId: string;
  cohortStartDate: string;
  cohortEndDate: string;
  show: boolean;
  refetchQueries: DocumentNode[];
  closeModal: () => void;
};

export function AddCohortStrandStandard({
  cohortId,
  cohortStartDate,
  cohortEndDate,
  show,
  closeModal,
  refetchQueries,
}: Props) {
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<Date>(getOrCreateDate(new Date()));
  const [endDate, setEndDate] = useState<Date>(
    getOrCreateDate(new Date(cohortEndDate))
  );
  const [applyToFullRange, setApplyToFullRange] = useState(false);
  const [items, setItems] = useState<
    AddCohortStrandStandard_SearchedStrandStandardFragment[]
  >([]);
  const [notes, setNotes] = useState<string>("");
  const [activeTab, setActiveTab] = useState<number>(0);

  const resetAndCloseModal = () => {
    closeModal();
    setErrorMsg("");
    setStartDate(getOrCreateDate(new Date()));
    setEndDate(getOrCreateDate(new Date(cohortEndDate)));
    setApplyToFullRange(false);
    setNotes("");
    setItems([]);
    setActiveTab(0);
  };

  const getIndex = (value: LinkType): number =>
    Object.values(LinkType).indexOf(value);

  const currentTabIsStrand = activeTab === getIndex(LinkType.Strand);
  const title = getTitle(activeTab);

  const currentQuery = currentTabIsStrand ? STRANDS_SEARCH : STANDARDS_SEARCH;

  const { data, loading } = useQuery(currentQuery, {
    variables: { cohortId },
    onError: (err) => fetchErrToast(title, err),
  });

  const itemsData: AddCohortStrandStandard_SearchedStrandStandardFragment[] =
    useMemo(() => {
      const fetchedData = currentTabIsStrand
        ? data?.getSearchStrands
        : data?.getSearchStandards;
      return fetchedData ?? [];
    }, [currentTabIsStrand, data?.getSearchStandards, data?.getSearchStrands]);

  const options = useMemo(() => {
    return itemsData.map((item) => ({
      value: item.id,
      label: `${item.key} - ${item.description}`,
    }));
  }, [itemsData]);

  const onSelect = (option: SearchSelectOption | null) => {
    if (option) {
      const selectedItem = itemsData.find((item) => item.id === option.value);
      if (selectedItem) {
        setItems((prev) => uniqBy([...prev, selectedItem], (item) => item.id));
      }
    }
  };

  const [createCohortItems, { loading: mutationLoading }] = useMutation(
    CREATE_COHORT_STANDARDS,
    {
      onError: (err) => setErrorMsg(err.message),
      onCompleted: resetAndCloseModal,
      refetchQueries,
    }
  );

  const handleRemoveItem = (index: number) => {
    setItems((prev) => prev.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (applyToFullRange) {
      setStartDate(new Date(cohortStartDate));
      setEndDate(new Date(cohortEndDate));
    }
  }, [applyToFullRange, cohortEndDate, cohortStartDate]);

  const disabled = !items.length || !startDate || !endDate;

  const onCreateCohortItems = async () => {
    const input: CreateCohortStrandStandardInput = {
      cohortId,
      applyToFullRange,
      fromDate: startDate.getTime(),
      toDate: endDate.getTime(),
      notes,
      strandIds: currentTabIsStrand ? items.map((item) => item.id) : [],
      standardIds: !currentTabIsStrand ? items.map((item) => item.id) : [],
    };

    await createCohortItems({
      variables: {
        input,
      },
    });
  };

  const handleTabChange = (index: number) => {
    setActiveTab((prev) => {
      if (prev === index) return prev;
      setItems([]);
      return index;
    });
  };

  return (
    <Modal
      show={show}
      title={`Add ${title}`}
      onClose={closeModal}
      icon={<Modal.Icon icon="strand" />}
      subtitle={`In this section, you can add multiple ${title.toLowerCase()} to the cohort.`}
      width="medium"
    >
      <div className="flex flex-col">
        <OptionsToggleButton
          options={["Add Strands", "Add Standards"]}
          setActiveTab={handleTabChange}
          activeTab={activeTab}
          height="h-[36px]"
          radius={8}
          className="z-0 mt-5 mb-[6px] col-start-3 col-span-8"
        />

        <TabContent
          activeTab={activeTab}
          cohortStartDate={cohortStartDate}
          cohortEndDate={cohortEndDate}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          applyToFullRange={applyToFullRange}
          setApplyToFullRange={setApplyToFullRange}
          options={options}
          items={items}
          onSelect={onSelect}
          handleRemoveItem={handleRemoveItem}
          notes={notes}
          setNotes={setNotes}
          loading={loading}
        />

        <ErrorBox msg={errorMsg} className="mt-4" />

        <Modal.Buttons className="pt-2">
          <Modal.Button
            type="confirm"
            loading={mutationLoading}
            disabled={disabled}
            onClick={onCreateCohortItems}
          >
            Save
          </Modal.Button>
          <Modal.Button type="cancel" onClick={resetAndCloseModal}>
            Cancel
          </Modal.Button>
        </Modal.Buttons>
      </div>
    </Modal>
  );
}
