import { gql } from "@apollo/client";

export const COHORT_SESSION_EVALUATION_FRAGMENT = {
  cohortSessionReport: gql`
    fragment SessionReportModal_CohortSessionReportEvaluation on CohortSessionEvaluationDetails {
      id
      subject
      startDateTime
      isGradingEnabled
    }
  `,
};

export const COHORT_SESSION_STUDENTS_FRAGMENT = {
  cohortSessionReport: gql`
    fragment SessionReportModal_CohortSessionReportStudents on StudentEvaluationRow {
      id
      fullName
      studentId
      startDate
      removedAt
      studentStatus
      grading {
        id
        studentId
        readingGrade
        classworkGrade
        languageArtsGrade
        participationGrade
        readingAbsentFromAssessment
        classworkAbsentFromAssessment
        languageArtsAbsentFromAssessment
      }
      attendance {
        id
        notes
        status
        studentId
        displayName
      }
    }
  `,
};

export const COHORT_SESSION_DETAILS_FRAGMENT = {
  cohortSessionReport: gql`
    fragment SessionReportModal_CohortSessionReportDetails on CohortSessionReportDetails {
      id
      subject
      cohortId
      cohortName
      subSubject
      endDateTime
      engagementId
      startDateTime
      engagementName
      instructionType
      instructionLevel
      engagementProductType
      tutorLink {
        id
        name
      }
      mentorLink {
        id
        name
      }
      substituteLinks {
        id
        name
      }
    }
  `,
};

export const COHORT_SESSION_REPORT_FRAGMENT = {
  cohortSessionReport: gql`
    fragment SessionReportModal_CohortSessionReport on CohortSessionReport {
      id
      strand
      flsLevel
      ufliUnit
      flsLesson
      classCode
      ufliLesson
      flsSkillCycle
      learningTarget
      off2ClassLesson
      instructionNotes
      priorityStandard

      updatedAt
      updatedBy {
        id
        fullName
      }
      submittedAt
      submittedBy {
        id
        fullName
      }

      sessionConcerns {
        id
        studentId
        concernType
        concernNotes
      }
      studentPerformance {
        id
        mastery
        studentId
        engagement
        performanceNotes
      }
    }
  `,
};
