import { StudentPerformance } from "../../../../../types";

export const NAME_W = 210;
export const ATTENDANCE_W = 140;
export const PERFORMANCE_MIN_W = 650;
export const MASTERY_W = 330;
export const MASTERY_READONLY_W = 130;
export const ENGAGEMENT_W = 240;
export const ENGAGEMENT_READONLY_W = 120;
export const NOTES_MIN_W = 210;

export const initStudentPerformance: StudentPerformance = {
  mastery: null,
  engagement: null,
  performanceNotes: null,
};

export const cellStyles = "flex items-center px-4 py-2";
