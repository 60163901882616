import {
  CohortSessionStudentAttendanceStatus,
  ProficiencyRating,
  SessionConcernType,
} from "@generated/graphql";
import { YesNoNull } from "components/shared/Inputs/YesOrNoInput";

export type SessionConcern = {
  studentId: string | null;
  concernNotes: string | null;
  concernType: SessionConcernType | null;
};

export type SessionConcernData = {
  hasConcerns: YesNoNull;
  concerns: SessionConcern[];
};

export type TutorCohortStudent = {
  studentId: string;
  studentName: string;
  studentPerformance?: StudentPerformance;
  attendanceStatus?: CohortSessionStudentAttendanceStatus;
};

export type InstructionData = {
  classCode: string | null;
  instructionNotes: string | null;

  flsLevel: string | null;
  flsLesson: string | null;
  flsSkillCycle: string | null;

  ufliUnit: string | null;
  ufliLesson: string | null;

  off2ClassLesson: string | null;

  strand: VIMMathStrands | null;
  learningTarget: WIMMathLearningTargets | null;

  priorityStandard: VIMElaPriorityStandards | null;
};

export type StudentPerformance = {
  performanceNotes: string | null;
  mastery: ProficiencyRating | null;
  engagement: ProficiencyRating | null;
};

export type StudentPerformanceMap = {
  [studentId: string]: StudentPerformance;
};

export enum VIMMathStrands {
  Other = "Other",
  KA = "KA",
  KB = "KB",
  KC = "KC",
  KD = "KD",
  OneA = "1A",
  OneB = "1B",
  OneC = "1C",
  OneD = "1D",
  TwoA = "2A",
  TwoB = "2B",
  TwoC = "2C",
  TwoD = "2D",
  ThreeA = "3A",
  ThreeB = "3B",
  ThreeC = "3C",
  ThreeD = "3D",
  FourA = "4A",
  FourB = "4B",
  FourC = "4C",
  FourD = "4D",
  FiveA = "5A",
  FiveB = "5B",
  FiveC = "5C",
  FiveD = "5D",
  SixA = "6A",
  SixB = "6B",
  SixC = "6C",
  SixD = "6D",
  SevenA = "7A",
  SevenB = "7B",
  SevenC = "7C",
  SevenD = "7D",
  EightA = "8A",
  EightB = "8B",
  EightC = "8C",
  EightD = "8D",
  AlgOneA = "Alg1A",
  AlgOneB = "Alg1B",
  AlgOneC = "Alg1C",
  AlgOneD = "Alg1D",
  GeoA = "GeoA",
  GeoB = "GeoB",
  GeoC = "GeoC",
  GeoD = "GeoD",
  AlgTwoA = "Alg2A",
  AlgTwoB = "Alg2B",
  AlgTwoC = "Alg2C",
  AlgTwoD = "Alg2D",
}

export enum WIMMathLearningTargets {
  Other = "Other",
  AssessedLtOne = "Assessed LT-1",
  AssessedLtTwo = "Assessed LT-2",
  AssessedLtThree = "Assessed LT-3",
  AssessedLtFour = "Assessed LT-4",
  AssessedLtFive = "Assessed LT-5",
  AssessedLtSix = "Assessed LT-6",
  AssessedLtSeven = "Assessed LT-7",
  AssessedLtEight = "Assessed LT-8",
  SupportingLtOne = "Supporting LT-1",
  SupportingLtTwo = "Supporting LT-2",
  SupportingLtThree = "Supporting LT-3",
  SupportingLtFour = "Supporting LT-4",
  SupportingLtFive = "Supporting LT-5",
  SupportingLtSix = "Supporting LT-6",
  SupportingLtSeven = "Supporting LT-7",
  SupportingLtEight = "Supporting LT-8",
  PrereqLtA = "Prereq LT-A",
  PrereqLtB = "Prereq LT-B",
  PrereqLtC = "Prereq LT-C",
  PrereqLtD = "Prereq LT-D",
  PrereqLtE = "Prereq LT-E",
}

export enum VIMElaPriorityStandards {
  Other = "Other",
  One = "1",
  Two = "2",
  Three = "3",
  Four = "4",
  Five = "5",
  Six = "6",
}

export enum FlsLevel {
  FirstDayActivity = "First Day Activity",
  GK = "GK",
  G1 = "G1",
  G2 = "G2",
  G3 = "G3",
}

export enum UfliUnit {
  FirstDayActivity = "First Day Activity",
  Alphabet = "Alphabet",
  AlphabetReviewAndLongerWords = "Alphabet Review & Longer Words",
  Digraphs = "Digraphs",
  VCE = "Vowel-Consonant-e (VCe)",
  ReadingLongerWords = "Reading Longer Words",
  EndingSpellingPatterns = "Ending Spelling Patterns",
  RControlledVowels = "R-Controlled Vowels",
  LongVowelTeams = "Long Vowel Teams",
  OtherVowelTeams = "Other Vowel Teams",
  DiphthongsAndSilentLetters = "Diphthongs and Silent Letters",
  SuffixesAndPrefixes = "Suffixes & Prefixes",
  SuffixSpellingChanges = "Suffix Spelling Changes",
  LowFrequencySpelling = "Low Frequency Spelling",
  AdditionalAffixes = "Additional Affixes",
}

export enum Off2ClassLesson {
  FirstDay = "First Day Activity",
  A0A1 = "A0.A.1 - Alphabet and letters",
  A0A2 = "A0.A.2 - Alphabet and letters",
  A0A3 = "A0.A.3 - Alphabet and letters",
  A0B1 = "A0.B.1 - Numbers",
  A0B2 = "A0.B.2 - Numbers",
  A0B3 = "A0.B.3 - Numbers",
  A0C1 = "A0.C.1 - In class",
  A0C2 = "A0.C.2 - In class",
  A0C3 = "A0.C.3 - In class",
  A0D1 = "A0.D.1 - Color, shape, and form",
  A0D2 = "A0.D.2 - Color, shape, and form",
  A0D3 = "A0.D.3 - Color, shape, and form",
  A0E1 = "A0.E.1 - People",
  A0E2 = "A0.E.2 - People",
  A0E3 = "A0.E.3 - People",
  A0F1 = "A0.F.1 - Verbs",
  A0F2 = "A0.F.2 - Verbs",
  A0F3 = "A0.F.3 - Verbs",
  A0G1 = "A0.G.1 - Food and drink",
  A0G2 = "A0.G.2 - Food and drink",
  A0G3 = "A0.G.3 - Food and drink",
  A0H1 = "A0.H.1 - Money and shopping",
  A0H2 = "A0.H.2 - Money and shopping",
  A0H3 = "A0.H.3 - Money and shopping",
  A0I1 = "A0.I.1 - At home",
  A0I2 = "A0.I.2 - At home",
  A0I3 = "A0.I.3 - At home",
  A0J1 = "A0.J.1 - Clothing",
  A0J2 = "A0.J.2 - Clothing",
  A0J3 = "A0.J.3 - Clothing",
  A0K1 = "A0.K.1 - Time, dates and weather",
  A0K2 = "A0.K.2 - Time, dates and weather",
  A0K3 = "A0.K.3 - Time, dates and weather",
  A0L1 = "A0.L.1 - Basic Adjectives",
  A0L2 = "A0.L.2 - Basic Adjectives",
  A0L3 = "A0.L.3 - Basic Adjectives",
  B11 = "B.1.1 - Let's start",
  B12 = "B.1.2 - Let's start",
  B13 = "B.1.3 - Let's start",
  B21 = "B.2.1 - The basics",
  B22 = "B.2.2 - The basics",
  B23 = "B.2.3 - The basics",
  B31 = "B.3.1 - Are you hungry?",
  B32 = "B.3.2 - Are you hungry?",
  B33 = "B.3.3 - Are you hungry?",
  B41 = "B.4.1 - Friends and family",
  B42 = "B.4.2 - Friends and family",
  B43 = "B.4.3 - Friends and family",
  B51 = "B.5.1 - Where we live",
  B52 = "B.5.2 - Where we live",
  B53 = "B.5.3 - Where we live",
  B61 = "B.6.1 - Jobs and routines",
  B62 = "B.6.2 - Jobs and routines",
  B63 = "B.6.3 - Jobs and routines",
  B71 = "B.7.1 - Let's go shopping",
  B72 = "B.7.2 - Let's go shopping",
  B73 = "B.7.3 - Let's go shopping",
  B81 = "B.8.1 - The past",
  B82 = "B.8.2 - The past",
  B83 = "B.8.3 - The past",
  B91 = "B.9.1 - How was your trip?",
  B92 = "B.9.2 - How was your trip?",
  B93 = "B.9.3 - How was your trip?",
  B101 = "B.10.1 - Now",
  B102 = "B.10.2 - Now",
  B103 = "B.10.3 - Now",
  B111 = "B.11.1 - People and things",
  B112 = "B.11.2 - People and things",
  B113 = "B.11.3 - People and things",
  B121 = "B.12.1 - In the future",
  B122 = "B.12.2 - In the future",
  B123 = "B.12.3 - In the future",
  UB131 = "UB.13.1 - All about people",
  UB132 = "UB.13.2 - All about people",
  UB133 = "UB.13.3 - All about people",
  UB134 = "UB.13.4 - All about people",
  UB141 = "UB.14.1 - What we do",
  UB142 = "UB.14.2 - What we do",
  UB143 = "UB.14.3 - What we do",
  UB144 = "UB.14.4 - What we do",
  UB151 = "UB.15.1 - Your life",
  UB152 = "UB.15.2 - Your life",
  UB153 = "UB.15.3 - Your life",
  UB154 = "UB.15.4 - Your life",
  UB161 = "UB.16.1 - What do you eat?",
  UB162 = "UB.16.2 - What do you eat?",
  UB163 = "UB.16.3 - What do you eat?",
  UB164 = "UB.16.4 - What do you eat?",
  UB171 = "UB.17.1 - Homes, towns, and neighborhoods",
  UB172 = "UB.17.2 - Towns, homes, and neighborhoods",
  UB173 = "UB.17.3 - Towns, homes, and neighborhoods",
  UB174 = "UB.17.4 - Towns, homes, and neighborhoods",
  UB181 = "UB.18.1 - Family, friends, and famous people",
  UB182 = "UB.18.2 - Family, friends, and famous people",
  UB183 = "UB.18.3 - Family, friends, and famous people",
  UB184 = "UB.18.4 - Family, friends, and famous people",
  UB191 = "UB.19.1 - On the move",
  UB192 = "UB.19.2 - On the move",
  UB193 = "UB.19.3 - On the move",
  UB194 = "UB.19.4 - On the move",
  UB201 = "UB.20.1 - Health and fitness",
  UB202 = "UB.20.2 - Health and fitness",
  UB203 = "UB.20.3 - Health and fitness",
  UB204 = "UB.20.4 - Health and fitness",
  UB211 = "UB.21.1 - Are they shopping?",
  UB212 = "UB.21.2 - Are they shopping?",
  UB213 = "UB.21.3 - Are they shopping?",
  UB214 = "UB.21.4 - Are they shopping?",
  UB221 = "UB.22.1 - Communicating",
  UB222 = "UB.22.2 - Communicating",
  UB223 = "UB.22.3 - Communicating",
  UB224 = "UB.22.4 - Communicating",
  UB231 = "UB.23.1 - Have you ever...?",
  UB232 = "UB.23.2 - Have you ever...?",
  UB233 = "UB.23.3 - Have you ever...?",
  UB234 = "UB.23.4 - Have you ever...?",
  UB241 = "UB.24.1 - Plans",
  UB242 = "UB.24.2 - Plans",
  UB243 = "UB.24.3 - Plans",
  UB244 = "UB.24.4 - Plans",
  LS11 = "LS.1.1 - English Phoneme Overview",
  LS12 = "LS.1.2 - English Phoneme Overview",
  LS13 = "LS.1.3 - English Phoneme Overview",
  LS14 = "LS.1.4 - English Phoneme Overview",
  LS21 = "LS.2.1 - Consonants and Short vowel 1",
  LS22 = "LS.2.2 - Consonants and Short vowel 1",
  LS23 = "LS.2.3 - Consonants and Short vowel 1",
  LS24 = "LS.2.4 - Consonants and Short vowel 1",
  LS31 = "LS.3.1 - Consonants and Short vowel 2",
  LS32 = "LS.3.2 - Consonants and Short vowel 2",
  LS33 = "LS.3.3 - Consonants and Short vowel 2",
  LS34 = "LS.3.4 - Consonants and Short vowel 2",
  LS41 = "LS.4.1 - Consonants and Short vowel 3",
  LS42 = "LS.4.2 - Consonants and Short vowel 3",
  LS43 = "LS.4.3 - Consonants and Short vowel 3",
  LS44 = "LS.4.4 - Consonants and Short vowel 3",
  LS51 = "LS.5.1 - Digraphs",
  LS52 = "LS.5.2 - Digraphs",
  LS53 = "LS.5.3 - Digraphs",
  LS54 = "LS.5.4 - Digraphs",
  LS61 = "LS.6.1 - VCe",
  LS62 = "LS.6.2 - VCe",
  LS63 = "LS.6.3 - VCe",
  LS64 = "LS.6.4 - VCe",
  LS71 = "LS.7.1 - Longer Word Patterns 1",
  LS72 = "LS.7.2 - Longer Word Patterns 1",
  LS73 = "LS.7.3 - Longer Word Patterns 1",
  LS74 = "LS.7.4 - Longer Word Patterns 1",
  LS81 = "LS.8.1 - Longer Word Patterns 2",
  LS82 = "LS.8.2 - Longer Word Patterns 2",
  LS83 = "LS.8.3 - Longer Word Patterns 2",
  LS84 = "LS.8.4 - Longer Word Patterns 2",
  LS91 = "LS.9.1 - R-Controlled vowel",
  LS92 = "LS.9.2 - R-Controlled vowel",
  LS93 = "LS.9.3 - R-Controlled vowel",
  LS94 = "LS.9.4 - R-Controlled vowel",
  LS101 = "LS.10.1 - Long Vowel Combinations & Diphthongs 1",
  LS102 = "LS.10.2 - Long Vowel Combinations & Diphthongs 1",
  LS103 = "LS.10.3 - Long Vowel Combinations & Diphthongs 1",
  LS104 = "LS.10.4 - Long Vowel Combinations & Diphthongs 1",
  LS111 = "LS.11.1 - Long Vowel Combinations & Diphthongs 2",
  LS112 = "LS.11.2 - Long Vowel Combinations & Diphthongs 2",
  LS113 = "LS.11.3 - Long Vowel Combinations & Diphthongs 2",
  LS114 = "LS.11.4 - Long Vowel Combinations & Diphthongs 2",
  LS121 = "LS.12.1 - Long Vowel Combinations & Diphthongs 3",
  LS122 = "LS.12.2 - Long Vowel Combinations & Diphthongs 3",
  LS123 = "LS.12.3 - Long Vowel Combinations & Diphthongs 3",
  LS124 = "LS.12.4 - Long Vowel Combinations & Diphthongs 3",
}
