import { ApolloError, DocumentNode, gql, useMutation } from "@apollo/client";
import { DeleteCohortStrandStandards_CohortStrandStandardFragment } from "@generated/graphql";
import {
  Checkbox,
  ErrorBox,
  Modal,
  triggerSuccessToast,
} from "components/shared";
import { getNormalizedShortReadableDate } from "helpers/dateText";
import { useEffect, useState } from "react";
import { LinkType } from "../types";
import { StandardStrandListItems } from "./StandardStrandListItems";

DeleteCohortStrandStandards.fragments = {
  cohortStrandStandard: gql`
    fragment DeleteCohortStrandStandards_CohortStrandStandard on CohortStrandStandard {
      id
      fromDate
      toDate
      cohortId
      strands {
        id
        key
        description
        url
        notes
      }
      standards {
        id
        key
        description
        url
        notes
      }
    }
  `,
};

const DELETE_COHORT_STRAND_STANDARDS = gql`
  mutation DeleteCohortStrandStandards(
    $input: DeleteCohortStrandStandardsInput!
  ) {
    deleteCohortStrandStandards(input: $input) {
      count
    }
  }
`;

type Props = {
  input: DeleteCohortStrandStandards_CohortStrandStandardFragment;
  show: boolean;
  refetchQueries: DocumentNode[];
  closeModal: () => void;
};

export function DeleteCohortStrandStandards({
  input,
  show,
  refetchQueries,
  closeModal,
}: Props) {
  const [errorMsg, setErrorMsg] = useState("");
  const [strands, setStrands] = useState(input.strands);
  const [standards, setStandards] = useState(input.standards);
  const [selectedStrandIds, setSelectedStrandIds] = useState<string[]>(
    input.strands.map((s) => s.id)
  );
  const [selectedStandardIds, setSelectedStandardIds] = useState<string[]>(
    input.standards.map((s) => s.id)
  );

  useEffect(() => {
    setStrands(input.strands);
    setStandards(input.standards);
    setSelectedStrandIds(input.strands.map((s) => s.id));
    setSelectedStandardIds(input.standards.map((s) => s.id));
  }, [input.strands, input.standards]);

  const [deleteCohortStrandStandards, { loading }] = useMutation(
    DELETE_COHORT_STRAND_STANDARDS,
    {
      onError: (err: ApolloError) => setErrorMsg(err.message),
      onCompleted: () => {
        closeModal();
        triggerSuccessToast({
          message: `Selected standards and strands were deleted.`,
        });
      },
      refetchQueries,
    }
  );

  const onDeleteCohortStrandStandards = async () =>
    await deleteCohortStrandStandards({
      variables: {
        input: {
          cohortId: input.cohortId,
          strandIds: selectedStrandIds,
          standardIds: selectedStandardIds,
        },
      },
    });

  const toggleStrandSelection = (id: string) => {
    setSelectedStrandIds((prev) =>
      prev.includes(id) ? prev.filter((s) => s !== id) : [...prev, id]
    );
  };

  const toggleStandardSelection = (id: string) => {
    setSelectedStandardIds((prev) =>
      prev.includes(id) ? prev.filter((s) => s !== id) : [...prev, id]
    );
  };

  const allSelected =
    selectedStrandIds.length === strands.length &&
    selectedStandardIds.length === standards.length;

  const toggleAll = () => {
    if (allSelected) {
      setSelectedStrandIds([]);
      setSelectedStandardIds([]);
    } else {
      setSelectedStrandIds(strands.map((s) => s.id));
      setSelectedStandardIds(standards.map((s) => s.id));
    }
  };

  return (
    <Modal
      show={show}
      onClose={closeModal}
      title="Delete Period Data"
      width="large"
      subtitle="This action cannot be undone."
      icon={<Modal.Icon icon="strand" type="danger" />}
    >
      <div className="flex flex-col gap-2 px-2">
        <div className="text-gray-600 text-sm mt-2 mb-2">
          {`Selected standards and strands for the period: ${getNormalizedShortReadableDate(
            new Date(input.fromDate)
          )} - ${getNormalizedShortReadableDate(
            new Date(input.toDate)
          )} will be deleted.`}
        </div>

        <div>
          <div className="flex items-center gap-2 justify-between">
            <h3 className="text-sm font-semibold text-gray-900">Strands</h3>
            {(strands.length > 0 || standards.length > 0) && (
              <Checkbox
                label="Select All"
                checked={allSelected}
                onChange={toggleAll}
                labelSide="left"
                labelClassName="text-sm font-semibold text-gray-900"
              />
            )}
          </div>
          {strands.length > 0 ? (
            <StandardStrandListItems
              items={strands}
              type={LinkType.Strand}
              selectedItemIds={selectedStrandIds}
              onToggleItem={toggleStrandSelection}
            />
          ) : (
            <p className="text-gray-500 text-sm">No strands to delete.</p>
          )}
        </div>

        <div>
          <h3 className="text-sm font-semibold text-gray-900">Standards</h3>
          {standards.length > 0 ? (
            <StandardStrandListItems
              items={standards}
              type={LinkType.Standard}
              selectedItemIds={selectedStandardIds}
              onToggleItem={toggleStandardSelection}
            />
          ) : (
            <p className="text-gray-500 text-sm">No standards to delete.</p>
          )}
        </div>

        {errorMsg && <ErrorBox msg={errorMsg} className="mt-2" />}

        <Modal.Buttons className="pt-4">
          <Modal.Button
            type="delete"
            loading={loading}
            onClick={() => onDeleteCohortStrandStandards()}
            disabled={
              selectedStrandIds.length === 0 && selectedStandardIds.length === 0
            }
          >
            Delete
          </Modal.Button>
          <Modal.Button type="cancel" onClick={closeModal}>
            Cancel
          </Modal.Button>
        </Modal.Buttons>
      </div>
    </Modal>
  );
}
