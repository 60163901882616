import { EngagementProductType, GradeLevel } from "@generated/graphql";
import { GradeLevelBadge } from "@shared/Badges/GradeLevelBadge";
import { getEngagementProductTypeText } from "@utils/labels";
import { Routes } from "@utils/routes";
import classNames, { clsx } from "clsx";
import {
  CohortAssignmentSubjectBadge,
  IconText,
  Link,
  Tooltip,
} from "components/shared";
import { SubjectComboWithAll } from "./AttendanceGrades/types";

// Displays (Cohort || Cohort Event || Cohort Session) Details on a single line

type Props = {
  cohortId?: string;
  className?: string;
  forHeader?: boolean;
  cohortName?: string;
  engagementId?: string;
  engagementName?: string;
  mentorTeacherName?: string;
  instructionLevel?: GradeLevel;
  productType?: EngagementProductType;
  subjectCombos?: SubjectComboWithAll[];
};

export const CohortInfoLine = ({
  cohortId,
  className,
  cohortName,
  productType,
  engagementId,
  subjectCombos,
  engagementName,
  instructionLevel,
  mentorTeacherName,
  forHeader = false,
}: Props) => (
  <div
    className={classNames("flex text-lg gap-x-[15px] items-center", className)}
  >
    {engagementName && (
      <IconText
        link
        forHeader={forHeader}
        icon="engagementFill"
        child={
          <Link
            customColor
            route={Routes.engagementsDashboard}
            routeProps={[`engagementId=${engagementId}`]}
          >
            {engagementName}
          </Link>
        }
      />
    )}

    {cohortName && (
      <IconText
        link
        forHeader={forHeader}
        icon="cohortFill"
        child={
          <Link
            customColor
            route={Routes.engagementsDashboard}
            routeProps={[`engagementId=${engagementId}&cohortId=${cohortId}`]}
          >
            {cohortName}
          </Link>
        }
      />
    )}

    {productType && (
      <Tooltip
        content={getEngagementProductTypeText(productType, "long")}
        tooltipProps={{ place: "right" }}
      >
        <IconText
          icon="productType"
          forHeader={forHeader}
          child={getEngagementProductTypeText(productType, "short")}
          iconClassName={clsx(forHeader ? "!mr-[5px]" : "!mr-2", "!mb-[2px]")}
        />
      </Tooltip>
    )}

    {instructionLevel && (
      <GradeLevelBadge className="-ml-[6px]" gradeLevel={instructionLevel} />
    )}

    {subjectCombos && subjectCombos.length > 0 && (
      <div className="flex no-wrap items-center flex-shrink gap-x-[6px] -ml-1">
        {subjectCombos.map(({ subject, subSubject }, i) => (
          <CohortAssignmentSubjectBadge
            key={i}
            cohortSubject={subject}
            cohortSubSubject={subSubject ?? undefined}
          />
        ))}
      </div>
    )}

    {mentorTeacherName && (
      <Tooltip content="Mentor Teacher" tooltipProps={{ place: "right" }}>
        <IconText
          icon="filledAvatar"
          forHeader={forHeader}
          iconClassName="!-ml-[6px] p-px"
          child={mentorTeacherName}
        />
      </Tooltip>
    )}
  </div>
);
