import { SessionReportStudentEvaluationRow } from "@shared/SessionReports/types";
import { getScrollbarStyle } from "@utils/styleStrings";
import clsx from "clsx";
import { YesOrNoInput } from "components/shared/Inputs/YesOrNoInput";
import { useMemo, useState } from "react";
import { subSectionStyles } from "../../../../../constants";
import { SessionModalPageSubHeader } from "../../../../../helpers";
import { SessionConcern, SessionConcernData } from "../../../../../types";
import { ConcernSectionHeader } from "./components/ConcernSectionHeader";
import { ConcernSectionRow } from "./components/ConcernSectionRow";
import { initConcern } from "./constants";
import { getStudentConcernOptions } from "./utils";

type Props = {
  readOnly: boolean;
  students: SessionReportStudentEvaluationRow[];
  concernsData: SessionConcernData;
  highlightIncomplete: boolean;
  setConcernsData: (data: SessionConcernData) => void;
};

export const ConcernsSection = ({
  students,
  readOnly,
  highlightIncomplete,
  concernsData: { hasConcerns, concerns },
  setConcernsData,
}: Props) => {
  const concernsNull = hasConcerns === null;
  const missingConcerns = hasConcerns && concerns.length === 0;
  const showError = highlightIncomplete && (concernsNull || missingConcerns);
  const [draftConcern, setDraftConcern] = useState<SessionConcern>(initConcern);

  const studentOptions = useMemo(
    () => getStudentConcernOptions(students),
    [students]
  );

  const deleteConcern = (index: number) =>
    setConcernsData({
      hasConcerns,
      concerns: concerns.filter((_, i) => i !== index),
    });

  const editConcern = (index: number, updatedConcern: SessionConcern) =>
    setConcernsData({
      hasConcerns,
      concerns: concerns.map((concern, i) =>
        i === index ? updatedConcern : concern
      ),
    });

  return (
    <div
      className={clsx(
        "flex flex-col gap-y-3 z-[20]",
        subSectionStyles,
        showError && "!bg-rose-100"
      )}
    >
      <div className="flex flex-col w-full gap-y-2">
        <SessionModalPageSubHeader title="Concerns" />

        <div className="flex -mt-1 items-center gap-x-3 text-base text-gray-700 font-semibold leading-none ml-px">
          Did any concerns come up in class today?
          <YesOrNoInput
            required
            className="mt-px"
            readOnly={readOnly}
            isYes={hasConcerns}
            onChange={(doesHaveConcerns) =>
              setConcernsData({
                hasConcerns: doesHaveConcerns,
                concerns,
              })
            }
          />
        </div>
        {hasConcerns && (
          <div className="flex flex-col gap-y-2 ml-px mt-2">
            <div className="flex items-center gap-x-3 text-gray-700 font-semibold leading-none">
              What specifically does TbT or the school partner need to know?
            </div>
            <div className="flex items-center gap-x-3 text-sm text-gray-600 leading-none">
              Select the type of concern and include as much detail as possible.
              Including student names, duration, frequency, and what you have
              already tried to resolve the issue, etc.
            </div>

            <div
              className={clsx(
                "block w-full h-full overflow-x-auto overflow-y-hidden mt-3",
                getScrollbarStyle("barOnly")
              )}
            >
              <div
                className={clsx(
                  "flex flex-col min-w-full w-fit rounded-lg border",
                  "border-gray-300 divide-y divide-gray-300"
                )}
              >
                <ConcernSectionHeader readOnly={readOnly} />

                {concerns.map((concern, i) => (
                  <ConcernSectionRow
                    key={i}
                    concern={concern}
                    readOnly={readOnly}
                    studentOptions={studentOptions}
                    zIndex={concerns.length + 1 - i}
                    isLastRow={i === concerns.length - 1 && readOnly}
                    editConcern={(c) => editConcern(i, c)}
                    removeConcern={() => deleteConcern(i)}
                  />
                ))}

                {!readOnly && (
                  <ConcernSectionRow
                    zIndex={0}
                    readOnly={readOnly}
                    isLastRow={!readOnly}
                    concern={draftConcern}
                    studentOptions={studentOptions}
                    editConcern={setDraftConcern}
                    addConcern={() => {
                      setConcernsData({
                        hasConcerns,
                        concerns: [...concerns, draftConcern],
                      });
                      setDraftConcern(initConcern);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
